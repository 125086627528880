.brand {
  font-family: "Nanum Myeongjo", serif;
  font-size: 1.75em;
  color: var(--text-sec);
}

.brand:hover {
  color: var(--text-main);
}

.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 1px;
  width: 100%;
  background: var(--highlight);
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}

.navbar-toggler.collapsed .top-bar {
  margin-top: -20px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 20px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .toggler-icon {
  background: var(--shadow);
}

.nav-link {
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--text-sec);
  padding: 15px 0px;
  margin: 0px 15px;
  display: inline-block;
  position: relative;
  opacity: 0.75;
}

.nav-link:hover {
  opacity: 1;
  color: var(--text-main);
}

.nav-link:focus {
  color: var(--text-main);
}

.nav-link::before {
  transition: 300ms;
  height: 1px;
  content: "";
  position: absolute;
  background-color: var(--shadow);
}

.nav-link-ltr::before {
  width: 0%;
  bottom: 5px;
}

.nav-link-ltr:hover::before {
  width: 100%;
  left: 0;
  right: 0;
}
