.title {
  font-family: "Nanum Myeongjo", serif;
  /* font-weight: bold; */
  color: var(--text-main);
}

.input {
  -webkit-appearance: none;
  box-shadow: none !important;
  background-color: var(--sec-bg);
  border-color: var(--divider);
  color: var(--text-sec);
}

.input:focus {
  background-color: var(--sec-bg);
  outline: 1px solid var(--shadow);
  outline-radius: 5px;
  color: var(--text-main);
}

.input:hover {
  background: var(--main-bg);
}

.select {
  background-color: var(--sec-bg);
  color: var(--text-main);
  border: 1px solid var(--text-sec);
  border-radius: 5px;
}

.submitButton {
  color: var(--text-main);
  background-color: var(--shadow-hover);
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}

.submitButton:hover {
  color: var(--text-main);
  background-color: var(--shadow-hover);
  opacity: 1;
}

.providerContainer {
  border-top: 1px solid var(--divider);
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
}

.providerSignInBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-main);
  background-color: var(--shadow-hover);
  border: none;
  border-radius: 0.25em;
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
  flex: 1 1 200px;
}

.providerSignInBtn:hover {
  color: var(--text-main);
  background-color: var(--shadow-hover);
  opacity: 1;
}

.providerSignInBtn:active {
  color: red;
}

.icon {
  color: #f5683c;
  height: 35px;
  width: 35px;
}
