:root {
  --main-bg: #f4f2ee;
  --sec-bg: white;
  --highlight: #f5683c;
  --highlight-hover: #e99469;
  --shadow: #277467;
  --shadow-hover: #48c7b3;
  --text-main: black;
  --text-sec: #4a4e52;
  --text-hover: #79736a;
  --divider: #e8e6e3;
}

.light {
  --main-bg: #f4f2ee;
  --sec-bg: white;
  --highlight: #f5683c;
  --highlight-hover: #e99469;
  --shadow: #277467;
  --shadow-hover: #48c7b3;
  --text-main: black;
  --text-sec: #4a4e52;
  --text-hover: #79736a;
  --divider: #e8e6e3;
}

.dark {
  --main-bg: #29241a;
  --sec-bg: #181a1b;
  --highlight: #e99469;
  --highlight-hover: #f5683c;
  --shadow: #48c7b3;
  --shadow-hover: #277467;
  --text-main: #e8e6e3;
  --text-sec: #bebeb4;
  --text-hover: #9e9689;
  --divider: #3b3b3b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--main-bg);
}
