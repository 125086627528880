.footerBottom {
  background-color: rgba(0, 0, 0, 0.09);
  color: var(--text-hover);
}

.iconContainer {
  border-bottom: 1px solid var(--divider);
}

.icon {
  color: #f5683c;
  height: 25px;
  width: 25px;
}

.icon {
  transition-duration: 0.15s;
}

.icon:hover {
  transform: scale(1.25);
  cursor: pointer;
  transition: 0.5 ease-in-out;
}

h6 {
  color: var(--text-sec);
}

.col {
  color: var(--text-sec);
}
